import { useEffect, useState } from "react";
import { InputFields, SelectList, SelectMultiple } from "./InputFields";

function FieldCreater(props) {
  const { headerName, outerStyles, labelStyles, whereClauseGenerator, data } =
    props;
  const [whereClause, setWhereClause] = useState("");
  useEffect(() => {
    if (whereClauseGenerator) {
      setWhereClause(whereClauseGenerator(data));
    }
  }, [data, whereClauseGenerator, setWhereClause]);
  return (
    <div className="form-group" style={{ flex: 1, ...(outerStyles || {}) }}>
      <label style={{ fontWeight: "300", ...(labelStyles || {}) }}>
        {headerName}
      </label>
      <GetInput {...props} whereClause={whereClause} />
    </div>
  );
}

function GetInput(props) {
  const { type } = props;
  switch (type) {
    case "list":
      return <SelectList {...props} />;
    case "multilist":
      return (
        <SelectMultiple {...props} value={props.value ? props.value : []} />
      );
    default:
      return <InputFields {...props} />;
  }
}

export default FieldCreater;
