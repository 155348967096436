import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import FormCreater from "./Form";

function fetchData(type = "", id, table, setData) {
  axios
    .get(
      `/get.php?name=${table}${type ? `&getType=${type}` : ""}${
        id ? `&id=${id}` : ""
      }`
    )
    .then(({ data }) => {
      setData(
        id ? { ...(data || {}) } : { id: parseInt(data?.lastId || 0) + 1 }
      );
    })
    .catch((err) => console.log(err));
}

function GenericAddCreator({ link, getColumns, label, table }) {
  const [data, setData] = useState({});

  const { updateId } = useParams();

  useEffect(() => {
    if (updateId) {
      fetchData("get_data_by_id", updateId, table, setData);
    } else {
      fetchData("get_last_id", null, table, setData);
    }
  }, [table, updateId]);
  // const navigate = useNavigate();
  const navigate = useNavigate();
  const onSave = (row, nav) => {
    axios
      .post("/post.php", {
        table: table,
        data: [row],
      })
      .then((res) => {
        alert(res.data);
      })
      .catch((err) => console.log(err));
    if (nav) {
      navigate(link);
    } else {
      setData((prev) => ({
        id: (prev.id || 0) + 1,
      }));
    }
  };
  return (
    <>
    {data.id && (
        <FormCreater
          label={label}
          onSave={onSave}
          setData={setData}
          data={data}
          columns={getColumns(link)}
        />
      )}
    </>
  );
}

export default GenericAddCreator;
