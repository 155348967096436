import axios from "axios";
import { useEffect, useState } from "react";
import { monthList } from "../Common/contants";
import DataGrid from "./Common/DataGrid";
import FieldCreater from "./Common/Form/FieldCreater";

function fetchData(type = "", id, table, setData, mrName) {
  axios
    .get(
      `/get.php?name=${table}${type ? `&getType=${type}` : ""}${
        id ? `&where=mrId%3D${id}` : ""
      }`
    )
    .then(({ data }) => {
      // console.log(data);
      data = data[0] || {};
      if (data.plan) {
        data.plan = JSON.parse(data.plan);
      }
      data.mrName = mrName;
      setData((prev) => ({ ...prev, ...data }));
    })
    .catch((err) => console.log(err));
}

function MonthlyTourPlanForMR({ link, userDetails }) {
  const [month, setMonth] = useState();
  const [mr, setMr] = useState();
  const [plan, setPlan] = useState();
  const [year, setYear] = useState();
  const [years, setYears] = useState([]);

  useEffect(() => {
    setYears([]);
    if (mr) {
      axios
        .get(`/get.php?name=monthly_tour_plan&where=mrId=${mr}`)
        .then((res) => {
          let arr = [];
          if (res.data.length)
            res.data.forEach((k) => {
              if (!arr.find((p) => p.id === k.year))
                arr.push({ id: k.year, value: k.year });
            });
          setYears(arr);
        })
        .catch((err) => console.log(err));
    }
  }, [mr]);

  useEffect(() => {
    setPlan([]);
    if (month && mr) {
      axios
        .get(
          encodeURI(
            `/get.php?name=monthly_tour_plan&where=mrId=${mr} and month=${month} and year=${year}`
          )
        )
        .then((res) => {
          if (res.data.length)
            setPlan(Object.values(JSON.parse(res.data[0].plan || "{}")));
          else setPlan([]);
        })
        .catch((err) => console.log(err));
    }
  }, [mr, month]);

  return (
    <>
      <div style={{ margin: 16 }}>
        <div style={{ display: "flex", gap: 16 }}>
          <FieldCreater
            headerName={"Select MR"}
            onChangeHandler={(e) => {
              setMr(e.target.value);
            }}
            value={mr}
            type="list"
            formLayoutAttr={{
              table: "mrs",
              tableKey: "mrName",
            }}
          />
          {years.length ? (
            <>
              <FieldCreater
                value={year}
                field="year"
                headerName={"Year"}
                type="list"
                values={years}
                onChangeHandler={(e) => setYear(e.target.value)}
                formLayoutAttr={{
                  type: "list",
                  tableKey: "value",
                  values: years,
                }}
              />

              <FieldCreater
                value={month}
                field="month"
                headerName={"Month"}
                type="list"
                onChangeHandler={(e) => setMonth(e.target.value)}
                formLayoutAttr={{
                  type: "list",
                  tableKey: "value",
                  values: Object.keys(monthList).map((key) => ({
                    id: monthList[key].key,
                    value: key,
                  })),
                }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div style={{ height: "500px" }}>
          <DataGrid
            data={plan || []}
            columns={
              [
                {
                  field: "date",
                  headerName: "Date",
                  gridLayoutAttr: {
                    valueGetter: (d) => new Date(d.data.date).toDateString(),
                  },
                },
                {
                  field: "cities",
                  headerName: "Cities",
                  gridLayoutAttr: {
                    valueGetter: (data) => {
                      return (
                        data?.data?.cities?.map((k) => k.name)?.join(",") ?? ""
                      );
                    },
                  },
                },
                {
                  field: "doctors",
                  headerName: "doctorname",
                  gridLayoutAttr: {
                    valueGetter: (data) => {
                      return (
                        data?.data?.doctors
                          ?.map((k) => k.doctorname)
                          ?.join(",") ?? ""
                      );
                    },
                  },
                },
                {
                  field: "workingWith",
                  headerName: "Working With",
                  gridLayoutAttr: {},
                },
                {
                  field: "activity",
                  headerName: "Activity",
                  gridLayoutAttr: {},
                },
              ] || []
            }
          />
        </div>
      </div>
    </>
  );
}

export default MonthlyTourPlanForMR;
