function ListViewCell({ data, name }) {
  return (
    <div>
      <ul>{(Array.isArray(data?.[name]) ? data[name] : [data[name]]).map((k) =>
        k ? <li>{k}</li> : <></>
      )}</ul>
    </div>
  );
}

export default ListViewCell;
