import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import EditDeleteColumn from "../Common/EditDeleteColumn";
import ListViewCell from "../Common/ListViewCell";
import ListViewGeneric from "../Common/ListViewGeneric";

const getColumns = (link) => [
  {
    field: "date",
    headerName: "Date",
    formLayoutAttr: {
      row: 1,
      inputProps: { disabled: true },
    },
    gridLayoutAttr: {
      valueGetter: (d) => {
        return new Date(d.data.date).toDateString();
      },
    },
  },
  {
    field: "doctorname",
    headerName: "Doctor",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    field: "products",
    headerName: "Products",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {
      cellStyle: { "white-space": "pre-wrap", "word-break": "break-word" },
      // cellRenderer: ListViewCell,
      autoHeight: true,
      // cellRendererParams: { name: "products" },
      style: { flex: 5 },
    },
  },
  {
    field: "gift",
    headerName: "Gift",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
  {
    field: "workingWith",
    headerName: "Working With",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
  {
    headerName: "Edit | Delete",
    gridLayoutAttr: {
      cellRenderer: EditDeleteColumn,
      cellRendererParams: {
        link: link,
        table: "doctor_report",
      },
    },
  },
];

function List(props) {
  const { label, table, link, userDetails } = props;
  const [listData, setListData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (userDetails.id) {
      axios
        .get("/getProductReport.php?where=mrId%3D" + userDetails.id)
        .then((res) => {
          let abc = {};
          (res.data || []).forEach((element) => {
            if (!abc[element.date + element.doctor_id]) {
              abc[element.date + element.doctor_id] = {
                ...element,
                products: element.productName ?? "",
              };
            } else {
              abc[element.date + element.doctor_id].products +=
                "\n" + (element.productName ?? "");
            }
          });
          setListData(Object.values(abc));
        })
        .catch((err) => console.log(err));
    }
  }, [table, userDetails.id, location.key]);

  return (
    <ListViewGeneric
      title={"Report"}
      data={listData}
      // otherAgGridProps={{
      //   getRowHeight: function (params) {
      //     // assuming 50 characters per line, working how how many lines we need
      //     return 100 * (Math.floor(params.data.products.length / 45) + 1);
      //   },
      // }}
      link={link}
      columns={getColumns(link)}
    />

    // <>{JSON.stringify(listData)}</>
  );
}

export default List;
