import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import FormCreater from "../Common/Form";
import AddForm from "./AddForm";

function AddStandardTourPlan({ link, userDetails }) {
  const navigate = useNavigate();
  const [mr, setMR] = useState({});

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <FormCreater
              columns={[
                {
                  field: "mrId",
                  headerName: "Select MR",
                  formLayoutAttr: {
                    row: 1,
                    type: "list",
                    table: "mrs",
                    tableKey: "mrName",
                    onChangeHandler: (e) => {
                      let index = e.nativeEvent.target.selectedIndex;
                      let label = e.nativeEvent.target[index].text;
                      setMR({
                        mrName: label,
                        mrId: e.target.value,
                      });
                    },
                  },
                },
              ]}
              data={mr}
              parentButtonInput={
                <button
                  disabled={!mr.mrId}
                  onClick={() => {
                    if (mr.mrId) {
                      let path = "upsert/" + mr.mrId;
                      navigate(path);
                    }
                  }}
                  className="btn blue m-b"
                >
                  Add Tour Plan
                </button>
              }
              label="Select MR"
              setData={setMR}
            />
          }
        />
        <Route
          path={"/upsert/:updateId"}
          element={<AddForm link={link} mr={mr} setMr={setMR} userDetails={userDetails} />}
        />
      </Routes>
    </>
  );
}

export default AddStandardTourPlan;
