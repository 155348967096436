import AddCity from "../Component/AddCity";
import AddDoctors from "../Component/AddDoctors";
import AddMonthlyTourPlan from "../Component/AddMonthlyTourPlan";
import AddMr from "../Component/AddMr";
import AddReports from "../Component/AddReport";
import AddStandardTourPlan from "../Component/AddStandardTourPlan";
import Dashboard from "../Component/Dashboard";
import ExpReports from "../Component/ExpenseReport";
import MonthlyTourPlanForMR from "../Component/MonthlyTourPlanReports";
import MrReports from "../Component/MRReport";

let navigators = [
  {
    label: "Dashboard",
    link: "/dashboard",
    classes: "bi bi-grid-3x3-gap-fill",
    component: <Dashboard />,
    role: ["admin", "mr"],
  },
  {
    label: "Doctors List",
    classes: "bi bi-grid-3x3-gap-fill",
    link: "/doctors",
    component: (props) => <AddDoctors link="/doctors" {...props} />,
    role: ["mr"],
  },
  {
    label: "ADMIN",
    role: ["admin"],
    classes: "bi bi-chevron-double-right",
    sub_links: [
      {
        label: "Add Doctors",
        link: "/doctors",
        component: (props) => <AddDoctors link="/doctors" {...props} />,
        role: ["admin"],
      },
      {
        label: "Add City",
        link: "/city",
        component: <AddCity link="/city" />,
        role: ["admin"],
      },
      {
        label: "Add MR",
        link: "/mr",
        component: <AddMr link="/mr" />,
        role: ["admin"],
      },
      {
        label: "Add Standard Tour Plan",
        link: "/standardToorPlan",
        component: (props) => (
          <AddStandardTourPlan {...props} link="/standardToorPlan" />
        ),
        role: ["admin"],
      },
    ],
  },
  {
    label: "Add Monthly Tour Plans",
    link: "/monthlyTourPlans",
    component: (props) => (
      <AddMonthlyTourPlan {...props} link={"/monthlyTourPlans"} />
    ),
    classes: "bi bi-sliders",
    role: ["mr"],
  },
  {
    label: "Add Reports",
    link: "/addreport",
    component: (props) => <AddReports {...props} link={"/addreport"} />,
    classes: "bi bi-sliders",
    role: ["mr"],
  },
  {
    label: "Product Reports",
    link: "/reports",
    component: (props) => <MrReports {...props} link={"/addreport"} />,
    classes: "bi bi-sliders",
    role: ["admin"],
  },
  {
    label: "Expense Reports",
    link: "/expReports",
    component: (props) => <ExpReports {...props} link={"/addreport"} />,
    classes: "bi bi-sliders",
    role: ["admin", "mr"],
  },
  {
    label: "Monthly Tour Plans",
    link: "/monthlytourplans",
    component: (props) => (
      <MonthlyTourPlanForMR {...props} link={"/addreport"} />
    ),
    classes: "bi bi-sliders",
    role: ["admin"],
  },
  {
    label: "Logout",
    link: "/logout",
    component: <div>Dashboar</div>,
    classes: "bi bi-list-ul",
    role: ["admin"],
  },
];

export default navigators;
