import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DoctorComp from "../Common/Form/CityDoctor";
import FieldCreater from "../Common/Form/FieldCreater";

function fetchData(type = "", id, table, setData) {
  axios
    .get(
      `/get.php?name=${table}${type ? `&getType=${type}` : ""}${
        id ? `&where=mrId%3D${id}` : ""
      }`
    )
    .then(({ data }) => {
      // console.log(data);
      data = data[0] || {};
      if (data.plan) {
        data.plan = JSON.parse(data.plan);
      }
      setData((prev) => ({ ...prev, ...data }));
    })
    .catch((err) => console.log(err));
}

function AddForm({ link, mr, setMr, userDetails }) {
  const navigate = useNavigate();
  const [data, setData] = useState({ ...(mr ?? {}) });

  let table = "standard_tour_plan";

  useEffect(() => {
    if (data.mrId) fetchData("get_data_by_mr_id", data.mrId, table, setData);
  }, [data.mrId, table]);

  const onSave = (row, nav) => {
    delete row.mrName;
    row.plan = JSON.stringify(row.plan);
    axios
      .post("/post.php", {
        table: table,
        data: [row],
      })
      .then((res) => {
        alert(res.data);
        setMr({});
        navigate(link);
      })
      .catch((err) => console.log(err));
    if (nav) {
      navigate(link);
    } else {
      //   setData((prev) => ({}));
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "24px",
      }}
    >
      <FieldCreater
        headerName="MR Name"
        field="mrName"
        value={data.mrName}
        setData={setData}
        data={data}
        formLayoutAttr={{
          type: "text",
          inputProps: { disabled: true },
        }}
      />
      {/* {JSON.stringify(data)} */}
      {[...Array(24).keys()].map((day) => {
        let row_id = `${Math.floor(day / 6)}_${day % 6}`;
        return (
          <InnerForm
            key={day}
            day={day % 6}
            mrId={data.mrId}
            i={day}
            row_id={row_id}
            data={data}
            cities={data?.plan?.[row_id]?.cities}
            setData={setData}
          />
        );
      })}
      <div>
        <button
          className="btn blue m-b"
          onClick={() => {
            onSave(data);
          }}
        >
          Save Plan
        </button>
      </div>
    </div>
  );
}

const daysInWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function InnerForm({ data, i, day, row_id, setData, cities, userDetails }) {
  const [updateCity, setUpdatedCity] = useState();

  useEffect(
    () =>
      !data[row_id] &&
      setData((prev) => ({
        ...prev,
        plan: {
          ...(prev.plan ?? {}),
          [row_id]: { id: `${daysInWeek[day]}` },
        },
      })),
    []
  );
  return (
    <div style={{ display: "flex", flex: 1, gap: 8 }}>
      <FieldCreater
        value={data?.plan?.[row_id]?.id}
        setData={setData}
        data={data}
        formLayoutAttr={{
          type: "text",
          inputProps: { disabled: true },
        }}
      />
      <FieldCreater
        field={"city"}
        headerName={"City"}
        setData={setData}
        outerStyles={{ flex: 5 }}
        data={data}
        {...{ type: "multilist", table: "city", tableKey: "name" }}
        onChangeHandler={(data) => {
          setData((prev) => {
            let up = data.find(
              (c) => !prev.plan?.[row_id]?.cities?.find((k) => k.id === c.id)
            )?.id;
            if (!up) {
              up = [
                prev.plan?.[i + 1]?.cities.find(
                  (c) => !data?.find((k) => k.id === c.id)
                )?.id,
              ];
            }
            setUpdatedCity(up);
            return {
              ...prev,
              plan: {
                ...(prev.plan || {}),
                [row_id]: {
                  ...(prev?.plan?.[row_id] || {}),
                  cities: data,
                },
              },
            };
          });
        }}
        valueGetterFunction={(k, v) => data?.plan?.[row_id]?.cities || []}
        formLayoutAttr={{
          type: "miltilist",
          table: "city&mrId=" + data.mrId,
          tableKey: "name",
        }}
      />
      <DoctorComp
        headerName={"Doctors"}
        updatedCity={updateCity}
        onCityChangeHandler={(data, remove) => {
          if (remove) {
            setData((prev) => ({
              ...prev,
              plan: {
                ...(prev.plan || {}),
                [row_id]: {
                  ...(prev?.plan?.[row_id] || {}),
                  doctors: (prev?.plan?.[row_id]?.doctors ?? []).filter((k) =>
                    remove.find((rm) => rm.id === k.id)
                  ),
                },
              },
            }));
          } else {
            setData((prev) => ({
              ...prev,
              plan: {
                ...(prev.plan || {}),
                [row_id]: {
                  ...(prev?.plan?.[row_id] || {}),
                  doctors: [...(prev?.plan?.[row_id]?.doctors ?? []), ...data],
                },
              },
            }));
          }
        }}
        onChangeHandler={(data, auto) => {
          setData((prev) => ({
            ...prev,
            plan: {
              ...(prev.plan || {}),
              [row_id]: {
                ...(prev?.plan?.[row_id] || {}),
                doctors: auto
                  ? [...(prev?.plan?.[row_id]?.doctors || []), ...data]
                  : data,
              },
            },
          }));
        }}
        outerStyles={{ flex: 5 }}
        valueGetterFunction={(k, v) => data?.plan?.[row_id]?.doctors || []}
        cities={cities}
        setData={setData}
        day={day}
      />
    </div>
  );
}

export default AddForm;
