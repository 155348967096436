import { useEffect, useState } from "react";
import FieldCreater from "../Common/Form/FieldCreater";

function AddForm({ defaultData, setParentData, updateId, index, doctorsList }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "0px 24px",
      }}
    >
      <div style={{ display: "flex", flex: 1, gap: 8 }}>
        <div style={{ flex: 3, display: "flex", gap: 8 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            {!updateId && (
              <button
                onClick={() =>
                  setParentData((prev) => {
                    let pr = [...prev];
                    pr.splice(index, 1);
                    return pr;
                  })
                }
                className="btn btn-danger btn-sm"
                style={{ marginLeft: "10px" }}
              >
                -
              </button>
            )}
          </div>{" "}
          <FieldCreater
            headerName={"Doctor"}
            field={"doctors"}
            outerStyles={{ flex: 3 }}
            data={defaultData}
            valueGetterFunction={({ id, doctor_id }) =>
              updateId ? doctor_id : id
            }
            {...{ type: "list", table: "doctors", tableKey: "doctorname" }}
            onChangeHandler={(data) => {
              setParentData((prev) => {
                let before = [...prev];
                before[index].doctors = data.target.value;
                before[index].isDoctorUpdated = true;
                before[index].doctor_id = data.target.value;
                return before;
              });
            }}
            // valueGetterFunction={(k, v) => data?.plan?.[i + 1]?.doctors || []}
            formLayoutAttr={{
              type: "list",
              // table: "doctors",
              values: doctorsList,
              tableKey: "doctorname",
            }}
          />
          <FieldCreater
            headerName={"Products"}
            field={"products"}
            outerStyles={{ flex: 5 }}
            type="multilist"
            data={defaultData}
            valueGetterFunction={() => {
              return (
                defaultData?.products?.filter(
                  (k) => !k.action || k.action !== "delete"
                ) ?? []
              );
            }}
            onChangeHandler={(data, inp) => {
              setParentData((prev) => {
                let before = [...prev];
                let dt = [...data];

                before[index]?.products?.forEach((element) => {
                  if (!data.find((k) => k.id === element.id)) {
                    data.push({ ...element, action: "delete" });
                  }
                });

                data = data.map((k) => {
                  let t = before[index]?.products?.find((f) => f.id === k.id);
                  let found = t?.table_id,
                    act = t?.action;
                  let dtFound = dt.find((l) => l.id === k.id);
                  if (found) {
                    return {
                      ...k,
                      table_id: found,
                      action: !dtFound ? "delete" : "add",
                    };
                  }
                  return {
                    ...k,
                    action: "add",
                  };
                });

                // data = data?.map((element) => {

                //   return {...element, action: before[index]?.products?.find((k) => k.id === element.id)}
                // });

                before[index].products = data;
                return before;
              });
            }}
            formLayoutAttr={{
              type: "miltilist",
              table: "addproduct",
              tableKey: "productName",
            }}
          />
          <FieldCreater
            field={"activity"}
            headerName={"Activity"}
            outerStyles={{ flex: 2 }}
            value={defaultData.activity}
            // valueGetterFunction={({ activity }) => activity}
            onChangeHandler={(data) => {
              setParentData((prev) => {
                let before = [...prev];
                before[index].activity = data.target.value;
                return before;
              });
            }}
            type="list"
            formLayoutAttr={{
              tableKey: "value",
              values: [
                { key: "Yes", value: "Yes" },
                { key: "No", value: "No" },
              ],
            }}
            // valueGetterFunction={(k, v) => data?.plan?.[i + 1]?.doctors || []}
          />
          <FieldCreater
            value={defaultData.gift}
            outerStyles={{ flex: 1 }}
            headerName={"Gift"}
            field="gift"
            onChangeHandler={(data) => {
              setParentData((prev) => {
                let before = [...prev];
                before[index].gift = data.target.value;
                return before;
              });
            }}
            formLayoutAttr={{
              type: "text",
            }}
          />
          <FieldCreater
            value={defaultData.exExp}
            outerStyles={{ flex: 1 }}
            headerName={"Extra Expense"}
            field="exExp"
            onChangeHandler={(data) => {
              setParentData((prev) => {
                let before = [...prev];
                before[index].exExp = data.target.value;
                return before;
              });
            }}
            formLayoutAttr={{
              type: "text",
            }}
          />
          <FieldCreater
            value={defaultData.expComment}
            outerStyles={{ flex: 1 }}
            headerName={"expComment"}
            field="expComment"
            onChangeHandler={(data) => {
              setParentData((prev) => {
                let before = [...prev];
                before[index].expComment = data.target.value;
                return before;
              });
            }}
            formLayoutAttr={{
              type: "text",
            }}
          />
          <FieldCreater
            field={"workingWith"}
            headerName={"Working With"}
            outerStyles={{ flex: 2 }}
            value={defaultData.workingWith}
            // valueGetterFunction={({ activity }) => activity}
            // onChangeHandler={(data) => {
            //   setData((prev) => ({
            //     ...prev,
            //   }));
            // }}
            onChangeHandler={(data) => {
              setParentData((prev) => {
                let before = [...prev];
                before[index].workingWith = data.target.value;
                return before;
              });
            }}
            type="list"
            formLayoutAttr={{
              tableKey: "value",
              values: [
                { key: "ASM", value: "ASM" },
                { key: "ZSM", value: "ZSM" },
                { key: "Individual", value: "Individual" },
              ],
            }}
            // valueGetterFunction={(k, v) => data?.plan?.[i + 1]?.doctors || []}
          />
          <FieldCreater
            value={defaultData.working}
            headerName={"Working"}
            field="working"
            onChangeHandler={(data) => {
              setParentData((prev) => {
                let before = [...prev];
                before[index].working = data.target.value;
                return before;
              });
            }}
            formLayoutAttr={{
              type: "text",
            }}
          />
          {/* <FieldCreater
            value={data["year"]}
            headerName={"Year"}
            setData={setData}
            data={data}
            formLayoutAttr={{
              type: "text",
              inputProps: { disabled: true },
            }}
          />
          <FieldCreater
            value={data["month"]}
            field="month"
            setData={setData}
            data={data}
            headerName={"Month"}
            {...{
              type: "list",
              tableKey: "value",
              values: Object.keys(monthList).map((key) => ({
                key,
                value: key,
              })),
            }}
            formLayoutAttr={{
              type: "list",
              tableKey: "value",
              values: Object.keys(monthList).map((key) => ({
                key,
                value: key,
              })),
            }}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default AddForm;
