import { useParams } from "react-router-dom";
import FieldCreater from "./FieldCreater";

function FormCreater({
  columns,
  data,
  label,
  setData,
  onSave,
  parentButtonInput,
}) {
  const { updateId } = useParams();
  let isUpdate = updateId ? true : false;
  columns = columns.filter((key) => key.formLayoutAttr);
  let rows = [...new Set(columns.map((a) => a?.formLayoutAttr?.row))];
  return (
    <>
      <div
        className="box-header"
        style={{ alignItems: "center", display: "flex", height: 68 }}
      >
        <h2 style={{ flex: 1 }}>
          <b>
            {isUpdate ? "Update" : "Add New"} {label}
          </b>
        </h2>
      </div>
      <div className="box-divider1" style={{ margin: 0, height: 0 }}></div>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          padding: 16,
        }}
      >
        {rows.map((key) => {
          return (
            <div key={key} style={{ display: "flex", gap: 16, margin: "10px" }}>
              {columns.map(
                (fld) =>
                  fld?.formLayoutAttr?.row === key && (
                    <FieldCreater
                      key={fld.name + fld.field}
                      value={data[fld.field] ? data[fld.field] : null}
                      setData={setData}
                      data={data}
                      {...fld}
                      {...(fld.formLayoutAttr || {})}
                    />
                  )
              )}
            </div>
          );
        })}
        <div
          className="form-group"
          style={{ display: "flex", gap: 4, margin: "10px" }}
        >
          {parentButtonInput ? (
            parentButtonInput
          ) : (
            <>
              <button
                onClick={() => {
                  onSave(data, true);
                }}
                className="btn blue m-b"
              >
                {isUpdate ? "Update" : "Add"} {label}
              </button>
              {!isUpdate && (
                <button
                  onClick={() => {
                    onSave(data);
                  }}
                  className="btn blue m-b"
                >
                  Add {label} and New
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FormCreater;
