import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function InputFields({
  field,
  value,
  setData,
  inputStyles,
  data,
  valueGetterFunction,
  formLayoutAttr,
  otherProps,
  onChangeHandler,
}) {
  let { type, inputProps } = formLayoutAttr;
  // if (valueGetterFunction) {
  //   setData((prev) => ({
  //     ...prev,
  //     [field]:
  //   }));
  // }
  return (
    <input
      type={type || "text"}
      value={
        value || (valueGetterFunction ? valueGetterFunction(data || {}) : "")
      }
      name={field}
      id={field}
      style={{ ...(inputStyles || {}), minWidth: "100px" }}
      onChange={(e) => {
        if (onChangeHandler) {
          onChangeHandler(e);
        } else {
          setData((prev) => {
            return { ...prev, [field]: e.target.value };
          });
        }
      }}
      className="form-control"
      {...(otherProps || {})}
      {...(inputProps || {})}
    />
  );
}

function SelectList({
  field,
  data,
  valueGetterFunction,
  onChangeHandler,
  value,
  headerName,
  setData,
  inputStyles,
  formLayoutAttr,
  otherProps,
}) {
  let { values, table, tableKey } = formLayoutAttr;
  let [dropdownValues, setDropdownValues] = useState(values || []);
  useEffect(() => {
    if (!values) {
      axios
        .get("/get.php?name=" + table)
        .then((res) => {
          setDropdownValues(
            res?.data?.map((key) => ({ id: key.id, [tableKey]: key[tableKey] }))
          );
        })
        .catch((err) => console.log(err));
    }
  }, [values, table, tableKey]);
  return (
    <select
      value={
        value || (valueGetterFunction ? valueGetterFunction(data || {}) : "")
      }
      onChange={(e) => {
        if (onChangeHandler) {
          onChangeHandler(e);
        } else {
          setData((prev) => {
            return { ...prev, [field]: e.target.value };
          });
        }
      }}
      name={field}
      className="form-control c-select"
      {...otherProps}
      {...inputStyles}
    >
      <option value={""}>Select {headerName}</option>
      {dropdownValues.map((key) => (
        <option key={key.id} value={key.id}>
          {key[tableKey]}
        </option>
      ))}
    </select>
  );
}

function SelectMultiple({
  field,
  value,
  onChangeFormatter,
  onChangeHandler,
  valueGetterFunction,
  setData,
  inputStyles,
  formLayoutAttr,
  otherProps,
  whereClause,
}) {
  let { values, table, tableKey } = formLayoutAttr;
  let [dropdownValues, setDropdownValues] = useState(values || []);
  useEffect(() => {
    if (!values) {
      axios
        .get("/get.php?name=" + table + (whereClause ? whereClause : ""))
        .then((res) => {
          setDropdownValues(
            (res?.data || [])?.map((key) => ({
              id: key.id,
              [tableKey]: key[tableKey],
            }))
          );
        })
        .catch((err) => console.log(err));
    }
  }, [values, table, tableKey, whereClause]);
  return (
    <Autocomplete
      multiple
      options={dropdownValues}
      getOptionLabel={(option) => option[tableKey]}
      // defaultValue={}
      size="small"
      {...(otherProps || {})}
      value={
        valueGetterFunction
          ? valueGetterFunction(
              typeof value === "string" ? value.split(",") : value,
              values
            )
          : value
      }
      className="autcomplete"
      onChange={(e, v) => {
        if (onChangeHandler) {
          onChangeHandler(v);
        } else {
          setData((prev) => {
            return {
              ...prev,
              ...(onChangeFormatter ? onChangeFormatter(v) : { [field]: v }),
            };
          });
        }
      }}
      ListboxProps={{ style: { fontSize: "14px" } }}
      renderInput={(params) => (
        <TextField
          // inputProps={{ style: { padding: "10px 0px" } }}
          {...params}
          sx={{
            ".MuiInputBase-root, .MuiButtonBase-root": {
              fontSize: "12px !important",
              fontFamily:
                '"Helvetica Neue",Helvetica,Arial,sans-serif !important',
            },
          }}
          placeholder="Products"
        />
      )}
    />
  );
}
export { InputFields, SelectList, SelectMultiple };
