import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";

function EditDeleteColumn({ data, link, table }) {
  // const deleteClick = () => {
  // }

  const navigate = useNavigate();
  const onDelete = () => {
    if (data.id) {
      let abc = window.confirm("Are you sure you want to delete this record?");
      if (abc) {
        axios
          .get(`/delete.php?name=${table}&id=${data.id}`)
          .then((res) => {
            navigate(link);
          })
          .catch((err) => console.log(err));
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
      }}
    >
      <NavLink
        to={`${link}/upsert/${data.id}`}
        className="btn btn-primary btn-sm"
      >
        <i className="bi bi-pencil-square"></i>
      </NavLink>
      <button
        onClick={onDelete}
        className="btn btn-danger btn-sm"
        style={{ marginLeft: "10px" }}
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
}

export default EditDeleteColumn;
