import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

function DoctorComp({
  setData,
  updatedCity,
  cities,
  valueGetterFunction,
  onCityChangeHandler,
  onChangeHandler,
  outerStyles,
  headerName,
  labelStyles,
  otherProps,
}) {
  const [productValues, setProductValues] = useState([]);

  useEffect(() => {
    if (updatedCity && Array.isArray(updatedCity)) {
      onCityChangeHandler(null, productValues);
    } else if (updatedCity) {
      onCityChangeHandler(
        productValues.filter((k) => k.city_id === updatedCity),
        null
      );
    }
  }, [updatedCity, productValues]);
  useEffect(() => {
    if (cities) {
      axios
        .get(
          `/get.php?name=doctors&where=city in (${
            cities?.map((key) => key.id).join(",") ?? "0"
          })`
        )
        .then((res) => {
          setProductValues(
            (res?.data || [])?.map((key) => ({
              id: key.id,
              doctorname: key.doctorname,
              city_id: key.city_id,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
          setProductValues([]);
        });
    } else {
      setProductValues([]);
    }
  }, [cities, setData]);
  return (
    <div className="form-group" style={{ flex: 1, ...(outerStyles || {}) }}>
      <label style={{ fontWeight: "300", ...(labelStyles || {}) }}>
        {headerName}
      </label>
      <Autocomplete
        multiple
        options={productValues}
        getOptionLabel={(option) => option.doctorname}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        // defaultValue={}
        size="small"
        {...(otherProps || {})}
        value={valueGetterFunction ? valueGetterFunction(productValues) : []}
        className="autcomplete"
        onChange={(e, v) => {
          if (onChangeHandler) {
            onChangeHandler(v);
          }
        }}
        ListboxProps={{ style: { fontSize: "14px" } }}
        renderInput={(params) => (
          <TextField
            // inputProps={{ style: { padding: "10px 0px" } }}
            {...params}
            sx={{
              ".MuiInputBase-root, .MuiButtonBase-root": {
                fontSize: "12px !important",
                fontFamily:
                  '"Helvetica Neue",Helvetica,Arial,sans-serif !important',
              },
            }}
            placeholder="Products"
          />
        )}
      />
    </div>
  );
}
export default DoctorComp;
