const monthList = {
  January: { key: 0, value: "January" },
  February: { key: 1, value: "February" },
  March: { key: 2, value: "March" },
  April: { key: 3, value: "April" },
  May: { key: 4, value: "May" },
  June: { key: 5, value: "June" },
  July: { key: 6, value: "July" },
  August: { key: 7, value: "August" },
  September: { key: 8, value: "September" },
  October: { key: 9, value: "October" },
  November: { key: 10, value: "November" },
  December: { key: 11, value: "December" },
};

export { monthList };
