import { NavLink } from "react-router-dom";
import DataGrid from "./DataGrid";

function ListViewGeneric({ link, title, data, columns, noNew, otherAgGridProps }) {
  columns = columns.filter((key) => key.gridLayoutAttr);
  return (
    <>
      <div
        className="box-header"
        style={{ alignItems: "center", display: "flex", height: 68 }}
      >
        <h2 style={{ flex: 1 }}>
          <b>{title}</b>
        </h2>
        {!noNew && <NavLink to={link + "/upsert"} className="btn btn-primary">
          Add New
        </NavLink>}
      </div>
      <div className="box-divider1" style={{ margin: 0, height: 0 }}></div>
      <div className="box-body" style={{ flex: 1 }}>
        <DataGrid data={data || []} columns={columns || []} otherAgGridProps={otherAgGridProps} />
      </div>
    </>
  );
}

export default ListViewGeneric;
