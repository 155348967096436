import { Route, Routes } from "react-router-dom";
import EditDeleteColumn from "./Common/EditDeleteColumn";
import GenericAddCreator from "./Common/GenericAddForm";
import GenericListView from "./Common/GenericListView";

const getColumns = (link) => [
  {
    field: "head_quarter",
    headerName: "Head Quarter",
    formLayoutAttr: {
      row: 1,
      type: "list",
      table: "headquarters",
      tableKey: "name",
    },
    gridLayoutAttr: {},
  },
  {
    field: "name",
    headerName: "Track Name",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    field: "km",
    headerName: "Kilometer",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
  {
    field: "dailyAllowance",
    headerName: "Daily Allowance",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
  {
    headerName: "Edit | Delete",
    gridLayoutAttr: {
      cellRenderer: EditDeleteColumn,
      cellRendererParams: {
        link: link,
        table: "city",
      },
    },
  },
];

function AddCity({ link }) {
  let label = "city";

  return (
    <Routes>
      <Route
        path="/"
        element={
          <GenericListView
            link={link}
            table={"city"}
            getColumns={getColumns}
            label={label}
          />
        }
      />
      <Route
        path={"/upsert/:updateId"}
        element={
          <GenericAddCreator
            getColumns={getColumns}
            label={label}
            table={"city"}
            updateData={{}}
            link={link}
          />
        }
      />
      <Route
        path={"/upsert"}
        element={
          <GenericAddCreator
            getColumns={getColumns}
            label={label}
            table={"city"}
            updateData={{}}
            link={link}
          />
        }
      />
    </Routes>
  );
}

export default AddCity;
