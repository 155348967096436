import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import EditDeleteColumn from "../Common/EditDeleteColumn";
import GenericListView from "../Common/GenericListView";
import AddForm from "./AddForm";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const getColumns = (link) => [
  {
    field: "mrName",
    headerName: "Name",
    formLayoutAttr: {
      row: 1,
      inputProps: { disabled: true },
    },
    gridLayoutAttr: {},
  },
  {
    field: "month",
    headerName: "Month",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {
      valueGetter: (data) => {
        return months[data.data.month];
      },
    },
  },
  {
    field: "year",
    headerName: "Year",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
  {
    headerName: "Edit | Delete",
    gridLayoutAttr: {
      cellRenderer: EditDeleteColumn,
      cellRendererParams: {
        link,
        table: "monthly_tour_plan",
      },
    },
  },
];

function fetchData(type = "", id, table, setData, mrName) {
  axios
    .get(
      `/get.php?name=${table}${type ? `&getType=${type}` : ""}${
        id ? `&where=mrId%3D${id}` : ""
      }`
    )
    .then(({ data }) => {
      // console.log(data);
      data = data[0] || {};
      if (data.plan) {
        data.plan = JSON.parse(data.plan);
      }
      data.mrName = mrName;
      setData((prev) => ({ ...prev, ...data }));
    })
    .catch((err) => console.log(err));
}

function AddMonthlyTourPlan({ link, userDetails }) {
  // const navigate = useNavigate();
  const [data, setData] = useState({ mrName: userDetails.mrName });
  // const [mrId, setMRID] = useState(5);

  // useEffect(() => {
  //   if (mrId) {
  //     fetchData(
  //       "get_data_by_mr_id",
  //       userDetails.id,
  //       "standard_tour_plan",
  //       setData,
  //       userDetails.mrName
  //     );
  //   }
  // }, [userDetails.id]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <GenericListView
              userDetails={userDetails}
              link={link}
              where={"&where=mrId=" + userDetails.id}
              table={"monthly_tour_plan"}
              getColumns={getColumns}
              label={"Monthly Tour Plans"}
            />
          }
        />

        <Route
          path={"/upsert/:updateId"}
          element={
            <AddForm
              userDetails={userDetails}
              getColumns={getColumns}
              link={link}
              data={data}
              setData={setData}
            />
          }
        />
        <Route
          path={"/upsert"}
          element={
            <AddForm
              userDetails={userDetails}
              getColumns={getColumns}
              link={link}
              data={data}
              setData={setData}
            />
          }
        />
      </Routes>
    </>
  );
}

export default AddMonthlyTourPlan;
