import axios from "axios";
import { useState } from "react";

function Login({ setUserDetails }) {
  const [logn, setLogn] = useState({});
  const [err, setErr] = useState("");
  const onLogin = () => {
    if (!logn.username || !logn.passwd) {
      return setErr("Please Enter username and password");
    }
    axios
      .post("/login.php", logn)
      .then(({ data }) => {
        if (data.role) {
          let date = new Date();
          const d = {
            ...data,
            expiry: new Date(date.getTime() + 60 * 60 * 1000),
          };
          localStorage.setItem("user", JSON.stringify(d));
          setUserDetails(d);
        } else {
          setErr(data.error);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5%",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        className="box"
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: "340px",
          gap: 8,
          padding: "16px",
        }}
      >
        <h4>Ovinfinity Login</h4>
        <label>Username</label>
        <input
          className="form-control"
          type={"text"}
          id="username"
          onChange={(e) =>
            setLogn((prev) => ({ ...prev, username: e.target.value }))
          }
          name="username"
        />
        <label>Password</label>
        <input
          className="form-control"
          type={"password"}
          id="passwd"
          onChange={(e) =>
            setLogn((prev) => ({ ...prev, passwd: e.target.value }))
          }
          name="passwd"
        />
        <span className="alert-danger">{err}</span>
        <button className="btn btn-success m-t" onClick={onLogin}>
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
