import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DoctorComp from "../Common/Form/CityDoctor";
import FieldCreater from "../Common/Form/FieldCreater";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === parseInt(month)) {
    days.push({
      day: date.getDay(),
      date: `${date.getFullYear()}-${padTo2Digits(
        date.getMonth() + 1
      )}-${padTo2Digits(date.getDate())}`,
    });
    date.setDate(date.getDate() + 1);
  }
  return days;
}

let monthList = {
  January: { key: 0, value: "January" },
  February: { key: 1, value: "February" },
  March: { key: 2, value: "March" },
  April: { key: 3, value: "April" },
  May: { key: 4, value: "May" },
  June: { key: 5, value: "June" },
  July: { key: 6, value: "July" },
  August: { key: 7, value: "August" },
  September: { key: 8, value: "September" },
  October: { key: 9, value: "October" },
  November: { key: 10, value: "November" },
  December: { key: 11, value: "December" },
};

function fetchPlan(setData, planWhereClause, month) {
  axios
    .get(
      `/get.php?name=standard_tour_plan${
        planWhereClause ? `&where=${planWhereClause}` : ""
      }`
    )
    .then(({ data }) => {
      setData((prev) => {
        let plan = JSON.parse(data?.[0]?.plan || "{}");
        return plan;
      });
    })
    .catch((err) => console.log(err));
}

function fetchData(type = "", id, table, setData, whereClause, mrName, setSTP) {
  axios
    .get(
      `/get.php?name=${table}${type ? `&getType=${type}` : ""}${
        id ? `&id=${id}` : ""
      }${whereClause ? `&where=${whereClause}` : ""}`
    )
    .then(({ data }) => {
      setData(
        id
          ? { ...(data || {}), plan: JSON.parse(data.plan || "{}"), mrName }
          : {
              id: parseInt(data?.lastId || 0) + 1,
              plan_code: `PLAN${parseInt(data?.lastId || 0) + 1}`,
              year: new Date().getFullYear(),
              mrName,
            }
      );
      if (!id) fetchPlan(setSTP, whereClause);
    })
    .catch((err) => console.log(err));
}

function AddForm({ data, setData, link, getColumns, userDetails }) {
  const navigate = useNavigate();

  let table = "monthly_tour_plan";

  const { updateId } = useParams();
  let [cities, setCities] = useState([]);
  let [doctors, setDoctors] = useState([]);
  const [s_t_p, setSTP] = useState({});
  useEffect(() => {
    axios
      .get("/get.php?name=city&where=head_quarter%3D" + userDetails.headQuarter)
      .then((res) => {
        setCities(res?.data?.map((key) => ({ id: key.id, name: key.name })));
      })
      .catch((err) => console.log(err));
    axios
      .get("/get.php?name=doctors")
      .then((res) => {
        setDoctors(res?.data?.map((key) => ({ id: key.id, name: key.name })));
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (updateId) {
      fetchData(
        "get_data_by_id",
        updateId,
        table,
        setData,
        "",
        userDetails.mrName
      );
    } else {
      fetchData(
        "get_last_id",
        null,
        table,
        setData,
        "mrId=" + userDetails.id,
        userDetails.mrName,
        setSTP
      );
    }
  }, [table, updateId, setData]);

  useEffect(() => {
    if (!updateId && s_t_p && data.month && data.year) {
      let dates = getDaysInMonth(data.month, data.year);
      let week = 0;
      let d = {};
      dates.forEach((day, i) => {
        let weekday = new Date(day.date).getDay();
        let row_id = `${week}_${weekday - 1}`;
        if (!d[i + 1]) {
          d[i + 1] = { date: day.date, ...(s_t_p[row_id] ?? {}) };
        } else {
          d[i + 1].date = day.date;
          d[i + 1] = { ...d[i + 1], ...(s_t_p[row_id] ?? {}) };
        }
        if (weekday === 6) {
          week = week + 1 > 3 ? 0 : week + 1;
        }
      });
      setData((prev) => ({ ...prev, plan: d }));
      // return {
      //   ...prev,
      //   plan: d,
      // };
    }
  }, [s_t_p, updateId, data.month, data.year]);

  const postData = (row, table, nav) => {
    axios
      .post("/post.php", {
        table: table,
        data: [row],
      })
      .then((res) => {
        alert(res.data);
        navigate(link);
      })
      .catch((err) => console.log(err));
    if (nav) {
      navigate(link);
    } else {
      //   setData((prev) => ({}));
    }
  };
  const onSave = (row, nav) => {
    delete row.mrName;
    row.mrId = userDetails.id;
    row.plan = row.plan !== "string" ? JSON.stringify(row.plan) : row.plan;
    if (updateId) {
      postData(row, table, nav);
    } else {
      axios
        .get(
          encodeURI(
            `/get.php?name=${table}&where=month=${data.month} and year=${data.year} and mrId=${userDetails.id}`
          )
        )
        .then((res) => {
          if (typeof res.data === "object" && res.data.length) {
            alert(
              `Data Already Exists for ${Object.keys(monthList).find((k) => {
                return monthList[k].key == data.month;
              })} ${data.year}.`
            );
          } else {
            postData(row, table, nav);
          }
        });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "24px 0px",
      }}
    >
      <div style={{ display: "flex", margin: "0px 24px", flex: 1, gap: 8 }}>
        <div style={{ flex: 3, display: "flex", gap: 8 }}>
          <FieldCreater
            value={data["plan_code"]}
            headerName={"Plan Code"}
            setData={setData}
            data={data}
            formLayoutAttr={{
              type: "text",
              inputProps: { disabled: true },
            }}
          />
          <FieldCreater
            value={data["mrName"]}
            headerName={"MR Name"}
            setData={setData}
            data={data}
            formLayoutAttr={{
              type: "text",
              inputProps: { disabled: true },
            }}
          />
          <FieldCreater
            value={data["year"]}
            field="year"
            headerName={"Year"}
            setData={setData}
            data={data}
            formLayoutAttr={{
              type: "text",
            }}
          />
          <FieldCreater
            value={data["month"]}
            field="month"
            setData={setData}
            data={data}
            headerName={"Month"}
            type="list"
            formLayoutAttr={{
              type: "list",
              tableKey: "value",
              values: Object.keys(monthList).map((key) => ({
                id: monthList[key].key,
                value: key,
              })),
            }}
          />
        </div>
      </div>
      <div className="divider m-b"></div>
      {/* {JSON.stringify(data)} */}
      <GetTable
        month={parseInt(data.month)}
        data={data}
        userDetails={userDetails}
        setData={setData}
        cities={cities}
        doctors={doctors}
      />
      <div>
        <button
          className="btn blue m-b m-l"
          onClick={() => {
            onSave(data);
          }}
        >
          Save Plan
        </button>
      </div>
    </div>
  );
}

function GetTable({ month, data, setData, doctors, cities, userDetails }) {
  let dates = month !== undefined ? getDaysInMonth(month, data.year) : [];
  const [updateCity, setUpdatedCity] = useState();

  return dates.map((day, i) => {
    return (
      <div style={{ display: "flex", margin: "0px 24px", flex: 1, gap: 8 }}>
        <FieldCreater
          value={day.date}
          headerName={i === 0 && "Date"}
          setData={setData}
          data={data}
          formLayoutAttr={{
            type: "date",
          }}
        />
        <FieldCreater
          field={"city"}
          setData={setData}
          outerStyles={{ flex: 5 }}
          data={data}
          headerName={i === 0 && "Cities"}
          values={cities}
          {...{ type: "multilist", tableKey: "name" }}
          onChangeHandler={(data) => {
            setData((prev) => {
              let up = data.find(
                (c) => !prev.plan?.[i + 1]?.cities?.find((k) => k.id === c.id)
              )?.id;
              if (!up) {
                up = [
                  prev.plan?.[i + 1]?.cities.find(
                    (c) => !data?.find((k) => k.id === c.id)
                  )?.id,
                ];
              }
              setUpdatedCity(up);

              return {
                ...prev,
                plan: {
                  ...(prev.plan || {}),
                  [i + 1]: {
                    ...(prev?.plan?.[i + 1] || {}),
                    cities: data,
                  },
                },
              };
            });
          }}
          valueGetterFunction={(k, v) => data?.plan?.[i + 1]?.cities || []}
          formLayoutAttr={{
            type: "miltilist",
            table: "city&where=head_quarter%3D" + userDetails.headQuarter,
            tableKey: "name",
          }}
        />
        <DoctorComp
          headerName={i === 0 && "Doctors"}
          outerStyles={{ flex: 5 }}
          cities={data?.plan?.[i + 1]?.cities}
          updatedCity={updateCity}
          onCityChangeHandler={(data, remove) => {
            if (remove) {
              setData((prev) => ({
                ...prev,
                plan: {
                  ...(prev.plan || {}),
                  [i + 1]: {
                    ...(prev?.plan?.[i + 1] || {}),
                    doctors: (prev?.plan?.[i + 1]?.doctors ?? []).filter(
                      (k) => remove.find((rm) => rm.id === k.id)
                    ),
                  },
                },
              }));
            } else {
              setData((prev) => ({
                ...prev,
                plan: {
                  ...(prev.plan || {}),
                  [i + 1]: {
                    ...(prev?.plan?.[i + 1] || {}),
                    doctors: [...(prev?.plan?.[i + 1]?.doctors ?? []), ...data],
                  },
                },
              }));
            }
          }}
          onChangeHandler={(data) => {
            setData((prev) => ({
              ...prev,
              plan: {
                ...(prev.plan || {}),
                [i + 1]: {
                  ...(prev?.plan?.[i + 1] || {}),
                  doctors: data,
                },
              },
            }));
          }}
          valueGetterFunction={(k, v) => data?.plan?.[i + 1]?.doctors || []}
        />
        <FieldCreater
          field={"workingWith"}
          setData={setData}
          headerName={i === 0 && "Working With"}
          outerStyles={{ flex: 5 }}
          data={data}
          type="list"
          onChangeHandler={(data) => {
            setData((prev) => ({
              ...prev,
              plan: {
                ...(prev.plan || {}),
                [i + 1]: {
                  ...(prev?.plan?.[i + 1] || {}),
                  workingWith: data.target.value,
                },
              },
            }));
          }}
          valueGetterFunction={(k, v) => {
            return data?.plan?.[i + 1]?.workingWith ?? null;
          }}
          formLayoutAttr={{
            tableKey: "value",
            values: [
              { key: "ASM", value: "ASM" },
              { key: "ZSM", value: "ZSM" },
              { key: "Individual", value: "Individual" },
            ],
          }}
        />
        <FieldCreater
          field={"activity"}
          setData={setData}
          headerName={i === 0 && "Activity"}
          outerStyles={{ flex: 5 }}
          data={data}
          type="list"
          onChangeHandler={(data) => {
            setData((prev) => ({
              ...prev,
              plan: {
                ...(prev.plan || {}),
                [i + 1]: {
                  ...(prev?.plan?.[i + 1] || {}),
                  activity: data.target.value,
                },
              },
            }));
          }}
          valueGetterFunction={(k, v) => {
            return data?.plan?.[i + 1]?.activity ?? null;
          }}
          formLayoutAttr={{
            type: "list",
            tableKey: "name",
            values: [
              { id: "Field Work", name: "Field Work" },
              { id: "Meeting", name: "Meeting" },
              { id: "Leave", name: "Leave" },
            ],
          }}
        />
      </div>
    );
  });
}

export default AddForm;
