import { Route, Routes } from "react-router-dom";
import EditDeleteColumn from "./Common/EditDeleteColumn";
import GenericAddCreator from "./Common/GenericAddForm";
import GenericListView from "./Common/GenericListView";

function AddDoctors({ link, userDetails }) {
  let label = "Doctor";
  const getColumns = (link) =>
    [
      {
        field: "docId",
        headerName: "ID",
        formLayoutAttr: {
          row: 1,
          outerStyles: {
            width: "10%",
            flex: "none",
          },
          valueGetterFunction: ({ id }) => `DOC${id}`,
          inputProps: { disabled: true },
        },
      },
      {
        field: "doctorname",
        headerName: "Doctor Name",
        formLayoutAttr: {
          row: 1,
        },
        gridLayoutAttr: {},
      },
      {
        field: "qualification",
        headerName: "Qualification",
        formLayoutAttr: {
          row: 1,
          type: "list",
          table: "qualificationlist",
          tableKey: "QualificationName",
        },
        gridLayoutAttr: {},
      },
      {
        field: "Specialization",
        headerName: "Specialization",
        formLayoutAttr: {
          row: 1,
          type: "list",
          table: "specializationlist",
          tableKey: "Specialization",
        },
        gridLayoutAttr: {},
      },
      {
        field: "frequency",
        headerName: "Frequency",
        formLayoutAttr: {
          row: 2,
          type: "list",
          values: [
            { id: "SPL", value: "SPL" },
            { id: "RPL", value: "RPL" },
            { id: "TPL", value: "TPL" },
          ],
          tableKey: "value",
        },
      },
      {
        field: "city",
        headerName: "City",
        formLayoutAttr: {
          row: 2,
          type: "list",
          table: "city",
          tableKey: "name",
        },
        gridLayoutAttr: {},
      },
      {
        field: "head_quarter",
        headerName: "Head Quarter",
        gridLayoutAttr: {},
      },
      {
        field: "Address",
        headerName: "Address",
        formLayoutAttr: {
          row: 2,
        },
      },
      {
        field: "mobile",
        headerName: "Mobile",
        formLayoutAttr: {
          row: 3,
        },
        gridLayoutAttr: {},
      },
      {
        field: "dob",
        headerName: "Dr.DOB",
        formLayoutAttr: {
          row: 3,
          outerStyles: {
            width: "20%",
            flex: "none",
          },
          type: "date",
        },
      },
      {
        field: "Products",
        headerName: "Products",
        formLayoutAttr: {
          row: 3,
          type: "multilist",
          table: "addproduct",
          tableKey: "productName",
        },
        gridLayoutAttr: {},
      },
      {
        headerName: "Edit | Delete",
        gridLayoutAttr: {
          cellRenderer: EditDeleteColumn,
          cellRendererParams: {
            link: link,
            table: "doctors",
          },
        },
      },
    ].filter((k) => userDetails.role !== "mr" || k.field);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GenericListView
            link={link}
            where={
              userDetails.role === "mr"
                ? `&where=c.head_quarter=${userDetails.headQuarter}`
                : ""
            }
            noNew={userDetails.role === "mr"}
            table={"doctors"}
            getColumns={getColumns}
            label={label}
          />
        }
      />
      <Route
        path={"/upsert/:updateId"}
        element={
          <GenericAddCreator
            getColumns={getColumns}
            label={label}
            table={"doctors"}
            updateData={{}}
            link={link}
          />
        }
      />
      <Route
        path={"/upsert"}
        element={
          <GenericAddCreator
            getColumns={getColumns}
            label={label}
            table={"doctors"}
            updateData={{}}
            link={link}
          />
        }
      />
    </Routes>
  );
}

export default AddDoctors;
