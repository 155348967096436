import { Route, Routes } from "react-router-dom";
import List from "./List";

function ExpReports(props) {
  return (
    <>
      <Routes>
        <Route path="/" element={<List {...props} />} />
      </Routes>
    </>
  );
}

export default ExpReports;
