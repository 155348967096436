import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FieldCreater from "../Common/Form/FieldCreater";
import AddForm from "./AddForm";

function Upsert({ link, userDetails }) {
  const navigate = useNavigate();
  const [date, setDate] = useState({ date: "" });
  const [data, setData] = useState([]);
  // const [mrId, setMRID] = useState(5);
  const { updateId } = useParams();
  const [doctorsList, setDoctorsList] = useState([]);
  useEffect(() => {
    axios
      .get(
        `/get.php?name=doctors&where=c.head_quarter%3D${userDetails.headQuarter}`
      )
      .then(({ data }) => {
        setDoctorsList(data ?? []);
      })
      .catch((err) => console.log(err));
  }, [userDetails]);
  useEffect(() => {
    if (updateId) {
      axios
        .get(
          "/getProductReport.php?where=mrId%3D" +
            userDetails.id +
            `&report_id=${updateId}&filter_by_other=1`
        )
        .then(({ data }) => {
          let abc = {};
          (data || []).forEach((element) => {
            if (!abc[element.date + element.doctor_id]) {
              abc[element.date + element.doctor_id] = {
                ...element,
                products: [],
              };
            }
            if (element.product_id) {
              abc[element.date + element.doctor_id].products.push({
                id: element.product_id,
                table_id: element.table_id,
                productName: element.productName,
              });
            }
          });
          if (data[0]) {
            setDate({
              report_id: data[0].id,
              date: data[0].date,
              autofill: true,
            });
          }
          setData(Object.values(abc));
        })
        .catch((err) => console.log(err));
    }
  }, [updateId]);
  useEffect(() => {
    if (date.date && !date.autofill) {
      let inpDate = new Date(date.date);
      let month = inpDate.getMonth();
      let day = inpDate.getDate();
      axios
        .get(
          `/get.php?name=monthly_tour_plan&where=year%3D${inpDate.getFullYear()}%20and%20month%3D${month}%20and%20mrId%3D${
            userDetails.id
          }`
        )
        .then(({ data }) => {
          data = data[0] || {};
          if (data.plan) {
            data.plan = JSON.parse(data.plan);
          }
          setData(data?.plan?.[day]?.doctors || []);
        })
        .catch((err) => console.log(err));
    }
  }, [date]);

  const onSave = () => {
    let saveData = data;
    let deleteProduct = [];
    saveData = saveData.map((element) => {
      element?.products
        ?.filter((k) => k.action && k.action === "delete")
        ?.forEach((e) => deleteProduct.push(e.table_id));
      let d = {
        doctor_id:
          updateId || element.isDoctorUpdated ? element.doctor_id : element.id,
        mrId: userDetails.id,
        date: date.date,
        ...(updateId ? { id: element.id } : {}),
        gift: element.gift,
        working: element.working,
        workingWith: element.workingWith,
        activity: element.activity,
        fk: [
          {
            table: "doctor_products",
            key: "report_id",
            data: element?.products
              ?.filter((k) => !k.action || k.action !== "delete")
              ?.map((key) => ({
                product: key.id,
                doctor:
                  updateId || element.isDoctorUpdated
                    ? element.doctor_id
                    : element.id,
                ...(key.table_id ? { id: key.table_id } : {}),
                ...(updateId ? { report_id: element.id } : {}),
                fk: [],
              })),
          },
        ],
      };
      return d;
    });

    axios
      .get(`/delete.php?name=doctor_products&ids=${deleteProduct.join()}`)
      .then((res) => {
        axios
          .post("/post.php", {
            table: "doctor_report",
            data: saveData,
          })
          .then((res) => {
            alert(res.data);
            navigate(link);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "24px 0px",
        }}
      >
        <div style={{ display: "flex", gap: 16, padding: "0px 24px" }}>
          <FieldCreater
            // value={data["report_code"]}
            headerName={"Report Id"}
            value={date.report_id}
            setData={setData}
            data={data}
            outerStyles={{ flex: 0 }}
            formLayoutAttr={{
              type: "text",
              inputProps: { disabled: true, style: { width: "200px" } },
            }}
          />
          <FieldCreater
            value={date.date}
            headerName={"Date"}
            setData={setDate}
            field={"date"}
            data={date}
            outerStyles={{ flex: 0 }}
            formLayoutAttr={{
              type: "date",
              inputProps: { style: { width: "200px" } },
            }}
          />
        </div>
        {data.map((element, i) => {
          return (
            <AddForm
              defaultData={element}
              index={i}
              doctorsList={doctorsList}
              updateId={updateId}
              key={i}
              setParentData={setData}
            />
          );
        })}
        <div style={{ margin: "0px 24px" }}>
          <button
            className="btn success"
            onClick={() => setData((prev) => [...prev, {}])}
          >
            +
          </button>
        </div>

        <div style={{ margin: "24px 24px" }}>
          <button className="btn blue" onClick={onSave}>
            Save Report
          </button>
        </div>
      </div>
    </>
  );
}

export default Upsert;
