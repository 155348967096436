import { Route, Routes } from "react-router-dom";
import List from "./List";
import Upsert from "./Upsert";

function AddReports(props) {
  return (
    <>
      <Routes>
        <Route path="/" element={<List {...props} />} />
        <Route path={"/upsert/:updateId"} element={<Upsert {...props} />} />
        <Route path={"/upsert"} element={<Upsert {...props} />} />
      </Routes>
    </>
  );
}

export default AddReports;
