import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PDFExportPanel from "../../Common/pdfExporter/pdfDownloadButton";
import EditDeleteColumn from "../Common/EditDeleteColumn";
import ListViewGeneric from "../Common/ListViewGeneric";
import MrMonthComponent from "../Common/MrMonthComponent";

const getColumns = (link, table) => [
  {
    field: "date",
    headerName: "Date",
    formLayoutAttr: {
      row: 1,
      inputProps: { disabled: true },
    },
    gridLayoutAttr: {
      valueGetter: (d) =>
        d.data.date ? new Date(d.data.date).toDateString() : "",
    },
  },
  {
    field: "name",
    headerName: "Track Name",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    field: "dailyAllowance",
    headerName: "Daily Allowence",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  // {
  //   field: "expense",
  //   headerName: "Expense",
  //   formLayoutAttr: {
  //     row: 1,
  //   },
  //   gridLayoutAttr: {},
  // },
  {
    field: "fare",
    headerName: "Fare",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: { style: { flex: 5 } },
  },
  {
    field: "exExp",
    headerName: "Extra Expenses",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
  {
    field: "total",
    headerName: "Total",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
];

function List(props) {
  const { label, table, link, userDetails } = props;
  const [listData, setListData] = useState([]);
  const location = useLocation();
  const [mr, setMr] = useState();
  const [month, setMonth] = useState();
  const [pinnedRowTotal, setPinnedRowTotal] = useState({});

  useEffect(() => {
    setListData([]);
    function fetchData(d) {
      let alreadyAdded = [];
      let totalRow = {
        srNo: "",
        dailyAllowance: 0,
        fare: 0,
        exExp: 0,
        total: 0,
        name: "Total",
      };
      axios
        .get(
          `/getExpenseReport.php?where=dr.mrId=${
            userDetails.role === "admin" ? mr : userDetails.id
          } and dr.date between ${month} `
        )
        .then((res) => {
          let abc = {};
          let date_track = {};
          (res.data || []).forEach((element) => {
            // console.log(res.data);
            if (element.name) {
              if (!abc[element.name + element.date]) {
                abc[element.name + element.date] = {
                  ...element,
                  dailyAllowance: parseInt(element.dailyAllowance),
                  fare: parseInt(element.petrol) * parseInt(element.km),
                  exExp: parseInt(element.exExp),
                };
              } else {
                abc[element.name + element.date].exExp += parseInt(
                  element.exExp
                );
              }
              abc[element.name + element.date].total =
                abc[element.name + element.date].dailyAllowance +
                abc[element.name + element.date].fare +
                abc[element.name + element.date].exExp;
              if (!date_track[element.name + element.date]) {
                totalRow.dailyAllowance =
                  parseInt(totalRow.dailyAllowance) +
                  parseInt(abc[element.name + element.date].dailyAllowance);
                date_track[element.name + element.date] = true;
                totalRow.fare =
                  parseInt(totalRow.fare) +
                  parseInt(abc[element.name + element.date].fare);
              }
              totalRow.exExp =
                parseInt(totalRow.exExp) +
                parseInt(abc[element.name + element.date].exExp);
            }
          });
          totalRow.total =
            parseInt(totalRow.dailyAllowance) +
            parseInt(totalRow.fare) +
            parseInt(totalRow.exExp);
          setPinnedRowTotal(totalRow);
          setListData(Object.values(abc));
        })
        .catch((err) => console.log(err));
    }
    if (userDetails.id && (userDetails.role !== "admin" || mr) && month) {
      axios.get(`/get.php?name=city`).then(({ data }) => {
        let c = {};
        data.forEach((k) => (c[k.id] = k));
        fetchData(c);
      });
    }
  }, [table, userDetails.id, location.key, mr, month]);

  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };
  return (
    <>
      <MrMonthComponent
        mr={mr}
        month={month}
        role={userDetails.role}
        setMonth={setMonth}
        setMr={setMr}
      />
      <PDFExportPanel gridApi={gridApi} columnApi={columnApi} />
      <ListViewGeneric
        title={"Report"}
        data={listData}
        otherAgGridProps={{
          pinnedBottomRowData: [pinnedRowTotal],
          onGridReady,
        }}
        noNew={true}
        link={link}
        columns={getColumns(link, table)}
      />
    </>
    // <>{JSON.stringify(listData)}</>
  );
}

export default List;
