import { useState } from "react";
import { NavLink } from "react-router-dom";

function LinkComponent({ label, sub_links, classes, link, role }) {
  let [open, setOpen] = useState(false);
  return (
    <li
      key={label}
      style={{
        marginTop: "16px",
        fontSize: "14px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {link ? (
        <NavLink
          to={link}
          className={(navData) => (navData.isActive ? "active-nav" : "")}
        >
          <div
            className={"nav-icons"}
            style={{ display: "flex" }}
            onClick={() => setOpen(!open)}
          >
            <LinkDesign
              open={open}
              label={label}
              sub_links={sub_links}
              classes={classes}
            />
          </div>{" "}
        </NavLink>
      ) : (
        <div
          className={"nav-icons "}
          style={{ display: "flex" }}
          onClick={() => setOpen(!open)}
        >
          <LinkDesign
            open={open}
            label={label}
            sub_links={sub_links}
            classes={classes}
          />
        </div>
      )}
      {open &&
        sub_links &&
        sub_links
          .filter((key) => key?.role?.includes(role))
          .map((key, i) => (
            <ul key={key.link + i}>
              <LinkComponent {...key} />
            </ul>
          ))}
    </li>
  );
}

function LinkDesign({ open, key, label, sub_links, classes }) {
  return (
    <>
      <span className="nav-icon">
        <i
          className={sub_links && open ? "bi bi-chevron-double-down" : classes}
        ></i>
      </span>
      <span className={"nav-label"}>{label}</span>
    </>
  );
}

export default LinkComponent;
