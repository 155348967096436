import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PDFExportPanel from "../../Common/pdfExporter/pdfDownloadButton";
import EditDeleteColumn from "../Common/EditDeleteColumn";
import ListViewGeneric from "../Common/ListViewGeneric";
import MrMonthComponent from "../Common/MrMonthComponent";

const getColumns = (link, table) => [
  {
    field: "date",
    headerName: "Date",
    formLayoutAttr: {
      row: 1,
      inputProps: { disabled: true },
    },
    gridLayoutAttr: {
      valueGetter: (d) => new Date(d.data.date).toDateString(),
    },
  },
  {
    field: "doctorname",
    headerName: "Doctor",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  {
    field: "head_quarter",
    headerName: "Headquarter",
    formLayoutAttr: {
      row: 1,
    },
    gridLayoutAttr: {},
  },
  // {
  //   field: "expense",
  //   headerName: "Expense",
  //   formLayoutAttr: {
  //     row: 1,
  //   },
  //   gridLayoutAttr: {},
  // },
  {
    field: "productName",
    headerName: "Products",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {
      cellStyle: { "white-space": "pre-wrap", "word-break": "break-word" },
      // cellRenderer: ListViewCell,
      autoHeight: true,
      // cellRendererParams: { name: "products" },
      style: { flex: 5 },
    },
  },
  {
    field: "gift",
    headerName: "Gift",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
  {
    field: "workingWith",
    headerName: "Working With",
    formLayoutAttr: {
      row: 1,
      type: "number",
    },
    gridLayoutAttr: {},
  },
];

function List(props) {
  const { label, table, link, userDetails } = props;
  const [listData, setListData] = useState([]);
  const location = useLocation();
  const [mr, setMr] = useState();
  const [month, setMonth] = useState();

  useEffect(() => {
    setListData([]);
    function fetchData(d) {
      let alreadyAdded = [];
      axios
        .get(
          `https://alfamr.ovinfinity.com/getProductReport.php?where=mrId=${mr} and dr.date between ${month} `
        )
        .then((res) => {
          let abc = {};
          (res.data || []).forEach((element) => {
            // console.log(res.data);
            // if (!alreadyAdded.includes(element.city)) {
            //   element.expense = parseInt(d[element.city]?.km ?? 0) * 2 + 200;
            //   alreadyAdded.push(element.city);
            // }
            element.head_quarter = d[element.city]?.head_quarter ?? "";
            if (!abc[element.date + element.doctor_id]) {
              abc[element.date + element.doctor_id] = element;
            } else {
              abc[element.date + element.doctor_id].productName +=
                "\n" + element.productName;
            }
          });
          console.log(abc);
          // console.log(res.data);
          setListData(Object.values(abc ?? {}));
        })
        .catch((err) => console.log(err));
    }
    if (userDetails.id && mr && month) {
      axios.get(`/get.php?name=city`).then(({ data }) => {
        let c = {};
        data.forEach((k) => (c[k.id] = k));
        fetchData(c);
      });
    }
  }, [table, userDetails.id, location.key, mr, month]);
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };
  return (
    <>
      <MrMonthComponent
        mr={mr}
        month={month}
        role={userDetails.role}
        setMonth={setMonth}
        setMr={setMr}
      />
      <PDFExportPanel gridApi={gridApi} columnApi={columnApi} />
      <ListViewGeneric
        title={"Report"}
        data={listData}
        otherAgGridProps={{
          onGridReady,
        }}
        noNew={true}
        link={link}
        columns={getColumns(link, table)}
      />
    </>
    // <>{JSON.stringify(listData)}</>
  );
}

export default List;
