function Dashboard() {
  return (
    <>
      <div
        className="box-header"
        style={{ alignItems: "center", display: "flex", height: 68 }}
      >
        <h2>
          <b>MR Management - DASHBOARD</b>
        </h2>
      </div>
      <div className="box-divider1" style={{ margin: 0, height: 0 }}></div>
      <div className="box-body">
        <div className="padding">
          <div className="margin">
            <h4 className="mb-0" style={{ fontWeight: 300 }}>
              Hi USERNAME, Welcome back
            </h4>
            <br />
            <h6>
              Awesome APPLICATION for your next MR Management solutions
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
